import { ref, watch } from "vue";

export function useModal() {
  const root = ref<HTMLElement | null>(null);

  const isShown = ref<boolean>(false);
  watch(root, () => {
    if (root.value) {
      root.value.addEventListener("hidden.bs.modal", () => {
        isShown.value = false;
      });
      root.value.addEventListener("shown.bs.modal", () => {
        isShown.value = true;
      });
    }
  });

  return {
    modalRef: root,
    isShown,
  };
}
